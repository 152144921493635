import React, { useEffect, useContext, useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { ApplicationContext } from "../../context/application-context";
import { apiClient } from "../../utils/api-client";
import { useHistory } from "react-router-dom";

export const LoginForm = () => {
  const { setIdentity } = useContext(ApplicationContext);
  const history = useHistory();
  const [error, setError] = useState("");

  const onLogin = async (event: any) => {
    event.preventDefault();
    setError("");

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries()) as any;

    const loginResponse = await apiClient.auth.login({
      email: formDataObj.email,
      password: formDataObj.password,
    });

    if (!loginResponse.accessToken) {
      setError("Invalid username or password");
      return;
    }

    const identity = await apiClient.getIdentity();

    console.log("*** IDENTITY RESPONSE", identity);

    if (identity) {
      setIdentity(identity);
      history.push("/account");
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <Form onSubmit={onLogin}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control name="email" type="email" placeholder="Enter email" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            name="password"
            type="password"
            placeholder="Password"
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Login
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}

      <div>
        <Button
          variant="secondary"
          type="button"
          onClick={async () => {
            const { loginUrl } = await apiClient.auth.getGoogleLoginRedirect();
            if (loginUrl) {
              window.location.href = loginUrl;
            }
          }}
        >
          Login with Google
        </Button>
      </div>
    </div>
  );
};
