import React, { useContext } from "react";
import { ApplicationContext } from "../context/application-context";

export const AccountPage = () => {
  const { identity } = useContext(ApplicationContext);

  // Redirect to login?
  if (!identity) {
    return (
      <div className="App">
        <header className="App-header">Forbidden</header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Account</h1>
        <h2>Subscription: {JSON.stringify(identity, null, 2)}</h2>
      </header>
    </div>
  );
};
