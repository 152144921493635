import React, { useContext } from "react";
import { ApplicationContext } from "./context/application-context";
import { Spinner } from "./components/Spinner";
import { HelmetProvider, Helmet } from "react-helmet-async";

export const AppContainer = ({ children }: { children: any }) => {
  const { loading } = useContext(ApplicationContext);

  if (loading) {
    return (
      <div>
        <Spinner /> bn: {process.env.REACT_APP_BUILD_NUMBER}
      </div>
    );
  }

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Smart Web Widgets</title>
          <meta
            name="description"
            content="Intelligent website widgets to improve audience engagement and conversion"
          />
        </Helmet>
      </HelmetProvider>
      {children}
    </div>
  );
};
