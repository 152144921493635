type ClientConfig = {
  siteUrl: string;
  authAppId: string;
  apiUrl: string;
  authApiUrl: string;
  loginRedirectUrl: string;
  logoutRedirectUrl: string;
  paypalClientId: string;
};

const prodConfig: ClientConfig = {
  siteUrl: "https://www.smartwebwidgets.com",
  authAppId: "2rfjn63kfkllkimdga2hkvscln",
  apiUrl: "https://api.smartwebwidgets.com",
  authApiUrl: "https://auth.smartwebwidgets.com",
  loginRedirectUrl: "https://www.smartwebwidgets.com/login",
  logoutRedirectUrl: "https://www.smartwebwidgets.com/logout",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
};

const devConfig: ClientConfig = {
  ...prodConfig,
  siteUrl: "https://dev.smartwebwidgets.com",
  authAppId: "25es7b1adpd0v5p5p81a254jta",
  apiUrl: "https://api-dev.smartwebwidgets.com",
  authApiUrl: "https://auth-dev.smartwebwidgets.com",
  loginRedirectUrl: "https://dev.smartwebwidgets.com/login",
  logoutRedirectUrl: "https://dev.smartwebwidgets.com/logout",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
};

const localConfig: ClientConfig = {
  ...devConfig,
  siteUrl: "http://localhost:3333",
  apiUrl: "http://localhost:3000",
  loginRedirectUrl: "http://localhost:3333/login",
  logoutRedirectUrl: "http://localhost:3333/logout",
  paypalClientId:
    "Ab6cyJxd0kN3KJ0iHEj_aP5KP2E6_Ssw7Iis0b-MGw4PiGwvDY_6aHAJRzRwWsWsKk4--w6W83YIXkSM",
};

export const config: ClientConfig = !process.env.REACT_APP_BUILD_ENV
  ? localConfig
  : process.env.REACT_APP_BUILD_ENV === "production"
  ? prodConfig
  : devConfig;
