import React, { useContext, useEffect, useState } from "react";
import { PaypalCheckout } from "../components/PaypalCheckout";
import { StripeCheckout } from "../components/StripeCheckout";
import { StripePricingTable } from "../components/StripePricingTable";
import { ApplicationContext } from "../context/application-context";
import { Container, CardGroup, Card, Button, ListGroup } from "react-bootstrap";
import { getLoginUrl } from "../utils/auth-api-client";
import { usePlans } from "../hooks/use-plans";
import { Form } from "react-bootstrap";
import { getQueryParam } from "../utils/query-params";
import { Spinner } from "../components/Spinner";
import { apiClient } from "../utils/api-client";

export const CheckoutPage = () => {
  const planId = getQueryParam("plan") as SubscriberPlan;
  const plans = usePlans();
  const selectedPlan = plans.find((p) => p.id === planId);

  const { identity } = useContext(ApplicationContext);
  const [subscriptionIntentId, setSubscriptionIntentId] = useState("");

  useEffect(() => {
    const prepareSubscription = async () => {
      const subscriptionPrep = await apiClient.prepareSubscription();
      setSubscriptionIntentId(subscriptionPrep.paymentIntentId);
    };

    prepareSubscription();
  }, [identity]);

  console.log("*** IDENTITY", identity);

  return (
    <div style={{ padding: "40px" }}>
      <h2>Checkout Page</h2>
      <div>
        <h3>Plan Details</h3>
        <div>name: {selectedPlan?.name}</div>
        <div>price: {selectedPlan?.pricingText}</div>
        <div>features: {JSON.stringify(selectedPlan?.features)}</div>
      </div>
      {identity ? (
        <div>
          <h3>Customer Details</h3>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter name"
                value={identity.name}
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={identity.email}
                disabled={true}
              />
            </Form.Group>
          </Form>
        </div>
      ) : (
        <div>
          <div>
            Existing Customer? Please <a href={getLoginUrl()}>login</a>.
          </div>
          <div>
            New Customer? Please{" "}
            <a href={getLoginUrl(undefined, true)}>Sign up</a>
          </div>
        </div>
      )}
      <div>
        <h3>Payment Form</h3>
        <div>{subscriptionIntentId ? "HERE" : <span>Please login</span>}</div>
      </div>
    </div>
  );
};
