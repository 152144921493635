import logo from "../dog.svg";
import React, { useContext } from "react";
import { ApplicationContext } from "../context/application-context";
import Button from "react-bootstrap/Button";
import { useApiInfo } from "../hooks/use-api-info";

export const HomePage = () => {
  const { welcomeText, updateWelcomeText, showModal } =
    useContext(ApplicationContext);

  const info = useApiInfo();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Smart Web Widgets v0.1 (
          {process.env.REACT_APP_BUILD_NUMBER || "local-build"})
        </h1>
        <div>BUILD_ENV: {process.env.REACT_APP_BUILD_ENV || "local"}</div>
        <div>AppInfo: {JSON.stringify(info)}</div>
        <h3>{welcomeText}</h3>
        <a href="#" onClick={() => updateWelcomeText("You clicked me!")}>
          Click here to test context
        </a>
        <Button
          onClick={() => showModal("Strange title", "This modal thing works!")}
        >
          Show Modal
        </Button>
      </header>
    </div>
  );
};
