import React, { useEffect, useContext, useState } from "react";
import { ApplicationContext } from "../context/application-context";
import { login } from "../utils/auth-api-client";
import { config } from "../utils/client-config";
import { useHistory } from "react-router-dom";
import { Spinner } from "../components/Spinner";
import { getQueryParam } from "../utils/query-params";
import { apiClient } from "../utils/api-client";
import { Form, Button } from "react-bootstrap";
import { LoginForm } from "../components/forms/LoginForm";
import { SignUpForm } from "../components/forms/SignupForm";
import { ForgotPasswordForm } from "../components/forms/ForgotPasswordForm";

export const GoogleLoginPage = () => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const doLogin = async () => {
      const data = await apiClient.auth.googleLoginCallback();
      setData(data);
    };

    doLogin();
  }, []);

  return (
    <div style={{ width: "100%", margin: "20px" }}>
      {data ? <div>Data: {JSON.stringify(data)}</div> : "Logging in..."}
    </div>
  );
};
